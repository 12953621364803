import React from "react"
import { Link } from "gatsby"

const CustomLink = ({ title, link }) => {
  return (
    <Link to={link}>
      <div style={{ display: "inline", color: "blue", fontWeight: "400" }}>
        {` ${title} `}
      </div>
    </Link>
  )
}

export default React.memo(CustomLink)
