import React from "react"

const SecondaryHeader = ({ title, headingPosition }) => {
  return (
    <div className="secondary-header">
      <div
        className="secondary-header__section"
        style={
          headingPosition == null || headingPosition === "center"
            ? { margin: "0 auto", textAlign: "center" }
            : { margin: 0, textAlign: "start" }
        }
      >
        {title}
      </div>
    </div>
  )
}

export default React.memo(SecondaryHeader)
