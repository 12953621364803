import React from "react"
import { Link } from "gatsby"
import { useLocation } from "@reach/router"
import Accordion from "react-fast-accordion"
import CustomLink from "../CustomLink"
import { navigate } from "gatsby"

export const topics = [
  {
    type: "group",
    label: "Registering on AlignerBase",
    description:
      "Get started on your journey to a perfect smile by easily registering and activating your AlignerBase account!",
    link: "registering-on-alignerbase",
    subTopics: [],
  },
  {
    type: "group",
    label: "Signing into your Dashboard",
    description:
      "Easily access all of your AlignerBase account features by signing in to your personalized dashboard.",
    link: "signing-into-your-dashboard",
    subTopics: [],
  },
  {
    type: "group",
    label: "The Main Dashboard",
    description:
      "Your AlignerBase command center awaits! Get a bird's eye view of all your AlignerBase activities in one convenient location.",
    link: "the-main-dashboard",
    subTopics: [
      { label: "Main Dashboard", link: "#Main Dashboard" },
      { label: "Account Overview", link: "#Account Overview" },
      { label: "Cases by Treatment Stage", link: "#Cases by Treatment Stage" },
      { label: "Financial Metrics", link: "#Financial Metrics" },
      { label: "Alerts", link: "#Alerts" },
      { label: "Messages", link: "#Messages" },
    ],
  },
  {
    type: "group",
    label: "The Patients Dashboard",
    description:
      "Keep your patient's treatment plan on track with ease by managing, viewing, and editing all patient-related information from the Patients Dashboard.",
    link: "the-patients-dashboard",
    subTopics: [
      { label: "Adding a New Patient", link: "#Adding a New Patient" },
      {
        label: "Deleting a Patient from the list",
        link: "#Deleting a Patient from the list",
      },
    ],
  },
  {
    type: "group",
    label: "The Orders Dashboard",
    description:
      "Take control of the entire aligner treatment plan development cycle from the Orders Dashboard - managing your orders has never been easier!",
    link: "the-orders-dashboard",
    subTopics: [
      {
        label: "Creating an Order for a Patient",
        link: "#Creating an Order for a Patient",
      },
      {
        label: "Viewing an Existing Order",
        link: "#Viewing an Existing Order",
      },
      { label: "Cancelling an Order", link: "#Cancelling an Order" },
      {
        label: "Editing Details of a Patient",
        link: "#Editing Details of a Patient",
      },
      {
        label: "Order Progression after submission to AlignerBase Team",
        link: "#Order Progression after submission to AlignerBase Team",
      },
      {
        label: "Approving a Treatment Plan",
        link: "#Approving a Treatment Plan",
      },
      {
        label: "Refinements after Approval",
        link: "#Refinements after Approval",
      },
      {
        label: "In case of Order Rejection from AlignerBase",
        link: "#In case of Order Rejection from AlignerBase",
      },
    ],
  },
  {
    type: "group",
    label: "Billing & Invoicing",
    description:
      "Keep your finances organized by viewing or changing your plan, updating billing information, and reviewing past invoices and receipts - all from the Billing and Invoicing Dashboard.",
    link: "billing-and-invoicing",
    subTopics: [
      { label: "Plans", link: "#Plans" },
      { label: "Upcoming Bill", link: "#Upcoming Bill" },
      { label: "Receipts and Invoices", link: "#Receipts and Invoices" },
      { label: "Payment Method", link: "#Payment Method" },
      { label: "Credits", link: "#Credits" },
    ],
  },
  {
    type: "group",
    label: "The Profile Dashboard",
    description:
      "Take control of your personal and business information, specify production and treatment preferences, and add new account users to streamline your experience with the Profile Dashboard.",
    link: "the-profile-dashboard",
    subTopics: [
      { label: "Adding Business Details", link: "#Adding Business Details" },
      { label: "Additional User Accounts", link: "#Additional User Accounts" },
      {
        label: "Understanding and Updating Preferences",
        link: "#Understanding and Updating Preferences",
      },
      { label: "How to Change Password", link: "#How to Change Password" },
    ],
  },
]

const SupportCenterTopicWrapper = ({ children }) => {
  const location = useLocation()
  const pathName = location.pathname
  const hash = location?.hash ? decodeURI(location?.hash) : ""

  const data = topics.map((item, index) => {
    return {
      ...item,
      id: index,
    }
  })

  const SummaryComponent = ({ label, link, subTopics, isOpen }) => {
    const open = pathName.includes(link)

    const hasMenu = subTopics.length
    return hasMenu ? (
      <div className={`header ${open ? "item-active" : ""}`}>
        {label}
        {hasMenu ? (
          <span className={(isOpen || open ? "open" : "") + " chevron"}>+</span>
        ) : (
          <></>
        )}
      </div>
    ) : (
      <div
        className={`custom-header ${open ? "item-active" : ""}`}
        onClick={() => navigate(`/support-center/${link}`)}
      >
        <span>{label}</span>
      </div>
    )
  }

  // component will get wrapped in <div class="acc-content">
  const DetailComponent = ({ link, subTopics }) => {
    if (subTopics.length > 0) {
      return (
        <ul>
          {subTopics.map((topicItem, index) => {
            return (
              <li key={topicItem.link + "-" + index}>
                <CustomLink
                  className="content-item-active"
                  title={topicItem.label}
                  link={`/support-center/${link}/${topicItem.link}`}
                />
              </li>
            )
          })}
        </ul>
      )
    }

    return <></>
  }

  return (
    <div className="support-center__topic-header">
      <ul>
        <h3 className="mb-4">Support Topics</h3>
        <Accordion
          items={data}
          multiExpand={false}
          SummaryComponent={SummaryComponent}
          DetailComponent={DetailComponent}
        />
      </ul>
      {children}
    </div>
  )
}

export default SupportCenterTopicWrapper
