import React from "react"
import Layout from "../../components/Layout"
import Seo from "../../components/Seo"
import SecondaryHeader from "../../components/SecondaryHeader"
import SupportCenterTopicWrapper from "../../components/SupportCenterLayout"

const TheMainDashboardPage = ({ data }) => {
  return (
    <Layout>
      <Seo title={`Easy to use cloud platform`} />
      <SecondaryHeader title={<>Support Center</>} />

      <div id="support-center-page">
        <section className="support-center">
          <div style={{ paddingBottom: "50px" }}></div>

          <SupportCenterTopicWrapper>
            <div className="support-center__topic-right">
              <h3 className="mb-4">
                Understanding the Billing & Invoicing Dashboard
              </h3>

              <p>
                Keep your finances organized by viewing or changing your plan,
                updating billing information, and reviewing past invoices and
                receipts - all from the Billing and Invoicing Dashboard.
              </p>

              <div className="mb-4" />

              <h5 id="Plans">
                Section: <strong>Plans</strong>
              </h5>

              <div className="mb-4" />

              <p>
                To view pricing and your billing plan, there is a Billing &
                Invoicing section that can be viewed from the left navigation
                pane.
              </p>

              <div className="mb-4" />

              <img src="https://alignerbase-public.s3.us-east-2.amazonaws.com/support-center/13.jpg" />

              <div className="mb-4" />

              <p>
                For each new user that signs up, there is a free trial period
                that allows 1 free order. (This is for a limited time){" "}
              </p>

              <div className="mb-4" />

              <img src="https://alignerbase-public.s3.us-east-2.amazonaws.com/support-center/13a.jpg" />

              <div className="mb-4" />

              <p>
                Once the trial period ends, all users are automatically shifted
                to the Pay As You Go plan which is listed in this section with
                pricing. However, users can also opt for the Enterprise Plan
                instead of Pay As You Go from this section.
              </p>

              <div className="mb-4" />

              <img src="https://alignerbase-public.s3.us-east-2.amazonaws.com/support-center/13b.jpg" />

              <div className="mb-4" />

              <p>
                If you opt to subscribe to the Enterprise plan, once you pay,
                you will be asked to add details about your Facility immediately
                or do it later. A Facility is your organization which can be
                either, a doctor's office, lab, clinic or more.
              </p>

              <div className="mb-4" />

              <p>
                The Plan that is currently set for your account is highlighted
                with a green button.
              </p>

              <div className="mb-4" />

              <h5 id="Upcoming Bill">
                Section: <strong>Upcoming Bill</strong>
              </h5>

              <div className="mb-4" />

              <p>
                In this section, you will see an <strong>estimation</strong> of
                your upcoming bill with a detailed breakdown of your charges.
                This does not include any taxes which might be applicable.
              </p>

              <div className="mb-4" />

              <img
                style={{ margin: "0 auto" }}
                src="https://alignerbase-public.s3.us-east-2.amazonaws.com/support-center/14.jpg"
                width="400px"
              />

              <div className="mb-4" />

              <p>
                For each new user that signs up, there is a free trial period
                that allows 1 free order. (This is for a limited time){" "}
              </p>

              <div className="mb-4" />

              <h5 id="Receipts and Invoices">
                Section: <strong>Receipts and Invoices</strong>
              </h5>

              <div className="mb-4" />

              <p>
                Here you can view all the receipts and invoices for your account
                at AlignerBase along with their status. You can view the
                individual receipt as well as the corresponding order from this
                section.
              </p>

              <div className="mb-4" />

              <img src="https://alignerbase-public.s3.us-east-2.amazonaws.com/support-center/15.jpg" />

              <div className="mb-4" />

              <p>
                You can filter receipts within a date range or with different
                invoice types.
              </p>

              <div className="mb-4" />

              <h5 id="Payment Method">
                Section: <strong>Payment Method</strong>
              </h5>

              <div className="mb-4" />

              <p>
                This section will let you view your payment details that you
                entered when creating an account. You can update and add new
                methods from this section. Payment methods may only be deleted
                once a new payment method has been added and made 'default'.
              </p>

              <div className="mb-4" />

              <img src="https://alignerbase-public.s3.us-east-2.amazonaws.com/support-center/16.jpg" />

              <div className="mb-4" />

              <h5 id="Credits">
                Section: <strong>Credits</strong>
              </h5>

              <div className="mb-4" />

              <p>
                For users under the monthly plan, they will have credits carried
                forward if they don't utilize all their subscription amount
                within a month.
              </p>

              <div className="mb-4" />

              <p>
                More, AlignerBase has discretion of awarding credits to users
                which can be viewed from this section as well.
              </p>

              <div className="mb-4" />
            </div>
          </SupportCenterTopicWrapper>
        </section>
      </div>
    </Layout>
  )
}

export default TheMainDashboardPage
